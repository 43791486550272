import React, { Component } from 'react'
import ReCAPTCHA from "react-recaptcha";

export default class Captcha extends Component {
  constructor(props) {
    super(props)
    this.recaptchaLoaded = this.recaptchaLoaded.bind(this)
    this.ref = React.createRef()
  }
  recaptchaLoaded() {
  }
  render() {
    return (
      this.props.show &&
      <div style={{ visibility: !this.props.show ? "hidden" : "visible", opacity: !this.props.show ? "0" : "1", transition: "all .5s" }} >
        <ReCAPTCHA
          ref={e => this.ref = e}
          sitekey={process.env.GATSBY_RECAPTCHA_V3_SECRET_KEY.toString()}
          onloadCallback={this.recaptchaLoaded}
          verifyCallback={value => { this.ref.reset(); this.props.submit(value) }}
        />
      </div>
    )
  }
}
