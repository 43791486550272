import React from 'react'
import { Link } from 'gatsby'
import { Captcha } from '../captcha'

import './Footer.scss'
import { LanguageContext } from '../../contexts/Language'

const style = {
  opacity: 0.5,
}

const activeStyle = {
  opacity: 1,
}

const data = {
  about: {
    title: { pt: 'SOBRE NÓS', en: 'ABOUT' }
  },
  services: {
    title: { pt: 'SERVIÇOS', en: 'SERVICES' }
  },
  customers: {
    title: { pt: 'CLIENTES', en: 'CUSTOMERS' }
  },
  contact: {
    title: { pt: 'CONTATO', en: 'CONTACT' }
  },
  privacy_policy: {
    title: { pt: 'POLÍTICA DE PRIVACIDADE', en: 'PRIVACY POLICY' }
  },
  newsletters: {
    desc: { pt: 'Receba nossos informativos, livros e conteúdos exclusivos', en: 'Receive our newsletters, books and exclusive content' }
  }
}

class Footer extends React.Component {
  constructor(props) {
    super(props)
    this.submitRef = React.createRef()
    this.state = {
      show: false,
      captchaValue: ''
    }
  }
  showCaptcha = e => {
    e.preventDefault()
    this.setState((state) => ({ ...state, show: true }))
  }
  render() {
    return (
      <LanguageContext.Consumer>
        {({ language }) =>
        (<>
          <footer>
            <div className="fundo">
              <div className="tracinho" />

              <Link
                to="/"
                className="sobre_nos"
                style={style}
                activeStyle={activeStyle}
              >
                {data.about.title[language]}
              </Link>

              <Link
                to="/services"
                className="servicos"
                style={style}
                activeStyle={activeStyle}
              >
                {data.services.title[language]}
              </Link>

              <Link
                to="#anchor_section4"
                className="clientes"
                style={style}
                activeStyle={activeStyle}
              >
                {data.customers.title[language]}
              </Link>

              <Link
                to="/services/#section3"
                className="contato"
                style={style}
                activeStyle={activeStyle}
              >
                {data.contact.title[language]}
              </Link>

              <a
                target='_blank'
                rel="noopener noreferrer"
                href="https://docs.google.com/document/d/e/2PACX-1vTh4ZMGfrFEyTE0GplKFQJLojqsCsdZAqHTQHFe2W8Ck9aWAXrnutY-h0-xiErBR2aNQbRmMSUTYZqa/pub"
                className="politica_de_privacidade"
                style={style}
                activeStyle={activeStyle}
              >
                {data.privacy_policy.title[language]}
              </a>

              <div className="endereco">
                  <span style={{ fontWeight: 'bold' }}>
                      Brasília, DF, 70340-000 {' '}
                  </span>
                  Sala 761 - Ed Multiempresarial
                  Brasília, ST SRTVS
                  QUADRA 701 BLOCO O
              </div>

              <h3 className="newsletters">EMAIL NEWSLETTERS</h3>
              <p>{data.newsletters.desc[language]}</p>

              <img
                src={require('../../assets/logo2.svg')}
                className="logo_2"
                alt="logo_2"
              />

              <form
                id="footer_form"
                action={process.env.GATSBY_API_URL}
                method="POST"
              >
                <label htmlFor="email">
                  <input
                    type="email"
                    placeholder="E-mail"
                    name="email"
                    className="email"
                    required
                    hidden={this.state.show}
                  />
                </label>
                <button ref={e => { this.submitRef = e }} type='submit' hidden />
                <input value={this.state.captchaValue} name='captchaValue' hidden />
                <div id='captcha'>
                  <Captcha show={this.state.show}
                    submit={(value) => {
                      this.setState(state => ({ ...state, captchaValue: value }))
                      value.length && this.submitRef.click()
                      this.setState(state => ({ ...state, show: false }))
                    }} />
                </div>
                {!this.state.show &&
                  <button onClick={this.showCaptcha} className="button_submit">
                    <img
                      src={require('../../assets/email_logo.svg')}
                      className="email_logo"
                      alt="email_logo"
                    />
                  </button>
                }
              </form>

              <hr />

              <a
                href="https://www.facebook.com/barlaventomentoria/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={require('../../assets/f_logo.svg')}
                  className="f_logo"
                  alt="f_logo"
                />
              </a>
              <img
                src={require('../../assets/t_logo.svg')}
                className="t_logo"
                alt="t_logo"
              />
              <a
                href="https://www.instagram.com/barlaventoestrategia/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={require('../../assets/i_logo.svg')}
                  className="i_logo"
                  alt="i_logo"
                />
              </a>
            </div>
          </footer>
        </>)
        }
      </LanguageContext.Consumer>
    )
  }
}

export default Footer
